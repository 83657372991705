import React, { Component, createRef, RefObject } from 'react';
import { connect } from 'react-redux';
import { createSelector } from 'reselect';
import classNames from 'classnames';
import styles from './Scrubber.module.scss';

import {
  setTime,
  setProgressPreview,
  unsetProgressPreview,
} from '../../actions/playActions';

import {
  progressSelector,
  currentTrackSelector,
} from '../../selectors';

type P = {
  className?: string;
  isPlaying?: boolean;
  progress: number;
  progressPreview?: number;
  duration?: number;
  setProgressPreview: Function;
  setTime: Function;
  unsetProgressPreview: Function;
};

class Scrubber extends Component<P, {}> {
  containerRef: RefObject<HTMLDivElement> = createRef();
  
  constructor(props) {
    super(props);

    this.handleDragInit = this.handleDragInit.bind(this);
    this.handleDragMove = this.handleDragMove.bind(this);
    this.handleDragEnd = this.handleDragEnd.bind(this);
    this.handleTouchInit = this.handleTouchInit.bind(this);
    this.handleTouchMove = this.handleTouchMove.bind(this);
    this.handleTouchEnd = this.handleTouchEnd.bind(this);
  }

  getProgressPreview(clientX: number): number {
    const container = this.containerRef.current;

    if (!container) {
      return 0;
    }
    // get rect position
    const { left, width } = container.getBoundingClientRect();
    
    return (clientX - left) / width;
  }

  handleDragInit(event: React.MouseEvent<HTMLDivElement>): void {
    const nextProgress = this.getProgressPreview(event.clientX);
    document.addEventListener('mousemove', this.handleDragMove, false);
    document.addEventListener('mouseup', this.handleDragEnd, false);
    this.props.setProgressPreview(100 * nextProgress);
  }

  handleDragMove(event: MouseEvent): void {
    const nextProgress = this.getProgressPreview(event.clientX);
    this.props.setProgressPreview(100 * nextProgress);
  }

  handleDragEnd(event: MouseEvent): void {
    const nextProgress = this.getProgressPreview(event.clientX);
    const nextTime = nextProgress * (this.props.duration || 0);

    this.props.setTime(nextTime);
    this.props.unsetProgressPreview();

    document.removeEventListener('mousemove', this.handleDragMove);
    document.removeEventListener('mouseup', this.handleDragEnd);
  }

  handleTouchInit(event: React.TouchEvent<HTMLDivElement>): void {
    const nextProgress = this.getProgressPreview(event.touches[0].clientX);

    document.addEventListener('touchmove', this.handleTouchMove, false);
    document.addEventListener('touchend', this.handleTouchEnd, false);

    this.props.setProgressPreview(100 * nextProgress);
  }

  handleTouchMove(event: TouchEvent): void {
    const nextProgress = this.getProgressPreview(event.touches[0].clientX);
    this.props.setProgressPreview(100 * nextProgress);
  }

  handleTouchEnd(event: TouchEvent): void {
    const nextProgress = this.getProgressPreview(
      event.changedTouches[0].clientX
    );
    const nextTime = nextProgress * (this.props.duration || 0);

    this.props.setTime(nextTime);
    this.props.unsetProgressPreview();

    document.removeEventListener('touchmove', this.handleTouchMove);
    document.removeEventListener('touchend', this.handleTouchEnd);
  }

  render() {
    const { progressPreview } = this.props;

    return (
      <div
        ref={this.containerRef}
        className={classNames([
          styles.container,
          this.props.className, {
            [styles.scrubbing]: !!progressPreview,
          },
        ])}>
        <div
          className={styles.hitbox}
          onTouchStart={this.handleTouchInit}
          onMouseDown={this.handleDragInit} />
        <div
          style={{ width: `${progressPreview || this.props.progress}%` }}
          className={classNames([styles.progress, {
            [styles.playing]: this.props.isPlaying,
          }])} />
      </div>
    );
  }
}

const mapStateToProps = createSelector(
  currentTrackSelector,
  progressSelector,
  state => state.player.playing,
  state => state.player.currentTime,
  state => state.player.progressPreview,
  ({ track }, { progress }, isPlaying, currentTime, progressPreview) => {
    return {
      duration: track && track.audio.duration,
      progress,
      isPlaying,
      currentTime,
      progressPreview,
    }
  }
)

const mapDispatchToProps = dispatch => ({
  setProgressPreview: (time: number) => dispatch(setProgressPreview(time)),
  setTime: (time: number) => dispatch(setTime(time)),
  unsetProgressPreview: () => dispatch(unsetProgressPreview())
})

// export default connect(scrubberSelector, mapDispatchToProps)(Scrubber);
export default connect(mapStateToProps, mapDispatchToProps)(Scrubber);
