
import React, { Component } from 'react';
import { connect } from 'react-redux';
import classNames from 'classnames';
import { currentTrackSelector } from '../../selectors';
// css
import styles from './Body.module.scss';
// components
import Loader from '../Loader';
import Logo from '../Logo';
// redux
import type { Track } from '../../reducers/tracks';

type P = {
  children: React.ReactNode;
  track: Track | null;
  className?: string;
};

class Body extends Component<P, {}> {
  renderLoading() {
    return <p>Loading...</p>;
  }

  render() {
    let content: any;

    if (!!this.props.track) {
      content = this.props.children;
    } else {
      content = (
        <span>
          <Logo className={styles.logo} />
          <Loader />
        </span>
      );
    }

    return (
      <div className={classNames([
        styles.container,
        this.props.className,
        { [styles.loading]: !this.props.track },
      ])}>
        {content}
      </div>
    );
  }
}

export default connect(currentTrackSelector)(Body);
