import {
  Record,
  Map,
  OrderedMap,
  OrderedSet,
} from 'immutable';

// Define interfaces for each record type
interface PlayerRecordProps {
  currentTrackId: string | null;
  currentOffset: number;
  playing: boolean;
  loading: boolean;
  context: any | null;
  progressPreview: any | null;
  currentTime: number;
  tracks: OrderedSet<TracksRecordProps>;
  favorites: OrderedSet<number>;
  sponsors: OrderedSet<SponsorsRecordProps>;
}

export interface SponsorsRecordProps {
  slug: string | null;
  sponsor: string | null;
  logo: string | null;
  logoBackgroundColor: string | null;
  url: string | null;
  summary: string | null;
  playlistContent: any | null;
  playlistName: string | null;
  duration: number | null;
}

interface CategoriesRecordProps {
  entities: OrderedMap<any, any>;
}

interface StorytellersRecordProps {
  entities: OrderedMap<any, any>;
  meta: Map<string, any>; // You might want to be more specific about the types in 'meta'
}

interface LocationsRecordProps {
  entities: OrderedMap<any, any>;
  meta: Map<string, any>; // You might want to be more specific about the types in 'meta'
}

export interface TracksRecordProps {
  entities: OrderedMap<any, any>;
  meta: Map<string, any>; // You might want to be more specific about the types in 'meta'
}

interface FilterStateProps {
  term: string | null;
  storytellerId: string | null;
  locationId: string | null;
  year: number | null;
  searching: boolean;
}

export const PlayerRecord = Record<PlayerRecordProps>({
  currentTrackId: null,
  currentOffset: 0,
  playing: false,
  loading: false,
  context: null,
  progressPreview: null,
  currentTime: 0,
  tracks: OrderedSet(),
  favorites: OrderedSet(),
  sponsors: OrderedSet(),
});

export const SponsorsRecord = Record<SponsorsRecordProps>({
  slug: null,
  sponsor: null,
  logo: null,
  logoBackgroundColor: null,
  url: null,
  summary: null,
  playlistContent: null,
  playlistName: null,
  duration: null,
});

export const CategoriesRecord = Record<CategoriesRecordProps>({
  entities: OrderedMap<any, any>(), // Use the factory method here
});

export const StorytellersRecord = Record<StorytellersRecordProps>({
  entities: OrderedMap<any, any>(), // Use the factory method here
  meta: Map({
    loading: false,
    status: null,
    error: null,
  }),
});

export const LocationsRecord = Record<LocationsRecordProps>({
  entities: OrderedMap<any, any>(), // Use the factory method here
  meta: Map({
    loading: false,
    status: null,
    error: null,
  }),
});

export const TracksRecord = Record<TracksRecordProps>({
  entities: OrderedMap<any, any>(), // Use the factory method here
  meta: Map({
    loading: false,
    status: null,
    error: null,
  }),
});

export const FilterState = Record<FilterStateProps>({
  term: null,
  storytellerId: null,
  locationId: null,
  year: null,
  searching: false,
});
