
import React, { Component } from 'react';
import classNames from 'classnames';
import styles from './Loader.module.scss';

type P = {
  className?: string;
};

export default class Loader extends Component<P, {}> {
  render() {
    return (
      <div className={classNames([styles.container, this.props.className])}>
        <div className={styles.foldingCube}>
          <div className={styles.cube} />
          <div className={styles.cube} />
          <div className={styles.cube} />
          <div className={styles.cube} />
        </div>
        <p>Loading...</p>
      </div>
    );
  }
}
