
import React, { Component } from 'react';
import { connect } from 'react-redux';
import classNames from 'classnames';
import moment from 'moment';

import {
  setStoryteller,
} from '../../actions/filterActions';

import {
  setCurrentTrackId,
  toggleFavorite,
} from '../../actions/trackSelectionActions';

import { play } from '../../actions/playActions';
import styles from './Track.module.scss';

import type { Track as TrackType } from '../../reducers/tracks';

type P = {
  dispatch: Function;
  playing: boolean;
  currentTrackId: string;
  leader: React.ReactNode;
  track: TrackType;
  favorite: boolean;
};

const getPressHandler = fn => {
  if ('ontouchstart' in window) {
    return {
      onTouchTap: (event) => {
        event.stopPropagation();
        fn(event);
      },
    };
  }

  return {
    onClick: fn,
  };
};

const getDoublePressHandler = fn => {
  if ('ontouchstart' in window) {
    return {
      onTouchTap: (event) => {
        event.stopPropagation();
        fn(event);
      },
    };
  }

  return {
    onDoubleClick: fn,
  };
};

class Track extends Component<P, {}> {
  constructor(props) {
    super(props);

    this.handleSelectTrack = this.handleSelectTrack.bind(this);
    this.handleAddFavorite = this.handleAddFavorite.bind(this);
  }

  shouldComponentUpdate(nextProps: P): boolean {
    const wasCurrent = this.props.currentTrackId === this.props.track.id;
    const isCurrent = nextProps.currentTrackId === this.props.track.id;

    return (this.props.playing !== nextProps.playing) ||
      (wasCurrent !== isCurrent) ||
      (this.props.favorite !== nextProps.favorite) ||
      this.props.leader !== nextProps.leader;
  }

  getTrackClassList(): string {
    return classNames([styles.listItem, {
      [styles.active]: this.props.track.id === this.props.currentTrackId,
    }]);
  }

  getFavoriteClassList(): string {
    return classNames([
      styles.favorite,
      'fa',
      this.props.favorite ? 'fa-check' : 'fa-plus',
    ]);
  }

  getLeaderClassList(): string {
    return classNames([
      styles.leader, {
        [styles.playing]: this.props.track.id === this.props.currentTrackId
          && this.props.playing,
      },
    ]);
  }

  getTitleClassList(): string {
    return classNames([styles.title, {
      [styles.active]: this.props.track.id === this.props.currentTrackId,
    }]);
  }

  getDuration(): string {
    return moment()
      .startOf('day')
      .seconds(this.props.track.audio.duration)
      .format('mm[m] ss[s]');
  }

  handleSelectTrack(): void {
    this.props.dispatch(setCurrentTrackId(parseInt(this.props.track.id)));
    this.props.dispatch(play());
  }

  handleAddFavorite(): void {
    this.props.dispatch(toggleFavorite(parseInt(this.props.track.id)));
  }

  render(): React.ReactNode {
    const date = moment(this.props.track.date, 'YYYY-MM-DD');
    const selectStoryteller = () => this.props.dispatch(
      setStoryteller(this.props.track.storyteller.id)
    );

    const { track } = this.props;

    const { storyteller } = track;
    const headshot = (storyteller.headshot || {}).url;

    if (!track) {
      return null;
    }

    return (
      <li
        key={track.id}
        {...getDoublePressHandler(this.handleSelectTrack)}
        className={this.getTrackClassList()}
      >
        <button
          {...getPressHandler(this.handleAddFavorite)}
          className={this.getFavoriteClassList()}
        />
        <span className={this.getLeaderClassList()}>
          {this.props.leader}
        </span>
        <span
          className={this.getTitleClassList()}
          onClick={this.handleSelectTrack}>
          {this.props.track.title}
        </span>
        <span
          onClick={selectStoryteller}
          className={styles.storyteller}
        >
          {headshot
            && (
            <span>
              <img
                alt={`Storyteller: ${storyteller.title}`}
                className={styles.headshot}
                src={headshot}
              />
            </span>)}
          <span>
            {storyteller.title}
          </span>
        </span>
        <span className={styles.date}>
          <span className={styles.label}>
            {date.format('MMMM DD, YYYY')}
          </span>
        </span>
        <span className={styles.duration}>
          <i className="fa fa-clock-o" /> {this.getDuration()}
        </span>
      </li>
    );
  }
}

const emptySelector = () => ({});
export default connect(emptySelector)(Track);
