
import { getTracks } from './apiActions';

type ClearAllFilters = {
  type: 'CLEAR_ALL_FILTERS';
};

export const CLEAR_ALL_FILTERS = 'CLEAR_ALL_FILTERS';

function clearAllFilters(): ClearAllFilters {
  return {
    type: CLEAR_ALL_FILTERS,
  };
}

export type ClearTrackCache = {
  type: 'CLEAR_TRACK_CACHE';
};

export const CLEAR_TRACK_CACHE = 'CLEAR_TRACK_CACHE';

function clearTrackCache(): ClearTrackCache {
  return {
    type: CLEAR_TRACK_CACHE,
  };
}

type SetQuery = {
  type: 'SET_QUERY';
  term: string;
};

export const SET_QUERY = 'SET_QUERY';
function updateQuery(term: string): SetQuery {
  return {
    type: SET_QUERY,
    term,
  };
}

export type UnsetQueryAction = { type: 'UNSET_QUERY', ids: any[] };
export const UNSET_QUERY = 'UNSET_QUERY';

function unsetQuery(): Function {
  return (dispatch, getState) => {
    const ids = getState().tracks.get('entities').sortBy(
      t => t.date,
      (a, b) => a < b ? 1 : -1
    )
    .keySeq()
    .toArray()
    .reduce((acc, current) => {
      return acc.concat(parseInt(current));
    }, []);

    dispatch({ type: UNSET_QUERY, ids });
  };
}

let debounceQuery: ReturnType<typeof setTimeout> | number = 0;

export function setQuery(term: string): Function {
  return dispatch => {
    clearTimeout(debounceQuery);
    dispatch(clearAllFilters());
    dispatch(updateQuery(term));

    if (!term.length) {
      dispatch(unsetQuery());
      return;
    }

    debounceQuery = setTimeout(() => {
      dispatch(clearTrackCache());
      dispatch(getTracks({
        offset: 0,
        limit: 50,
        term: term,
      }));
    }, 225);
  };
}

type SetStorytellerId = {
  type: 'SET_STORYTELLER_ID';
  id: number;
}

export const SET_STORYTELLER_ID = 'SET_STORYTELLER_ID';

function setStorytellerId(id: number): SetStorytellerId {
  return {
    type: SET_STORYTELLER_ID,
    id,
  };
}

export function setStoryteller(id: number): Function {
  return dispatch => {
    dispatch(updateQuery(''));
    dispatch(clearAllFilters());
    dispatch(setStorytellerId(id));
    dispatch(clearTrackCache());
    dispatch(getTracks({
      offset: 0,
      limit: 50,
      storytellerId: id,
    }));
  };
}

export function unsetStoryteller(): Function {
  return dispatch => {
    dispatch(clearAllFilters());
    dispatch(unsetQuery());
  };
}

type SetLocationId = {
  type: 'SET_LOCATION_ID';
  id: number;
}

export const SET_LOCATION_ID = 'SET_LOCATION_ID';

function setLocationId(id: number): SetLocationId {
  return {
    type: SET_LOCATION_ID,
    id,
  };
}

export function setLocation(id: number): Function {
  return dispatch => {
    dispatch(updateQuery(''));
    dispatch(clearAllFilters());
    dispatch(setLocationId(id));
    dispatch(clearTrackCache());
    dispatch(getTracks({
      offset: 0,
      limit: 50,
      locationId: id,
    }));
  };
}

export function unsetLocation(): Function {
  return dispatch => {
    dispatch(clearAllFilters());
    dispatch(unsetQuery());
  };
}

export type FilterAction =
  SetQuery |
  ClearTrackCache |
  SetStorytellerId |
  SetLocationId |
  ClearAllFilters;
