
import { TracksRecord } from './lib/records';

import {
  clearMeta,
  setLoading,
  setError,
  mergeEntities,
  mergeMeta,
} from './lib/core';

import { GET_TRACK_SUCCESS } from '../store/hash';

import {
  GET_TRACKS_REQUEST,
  GET_TRACKS_SUCCESS,
  GET_TRACKS_FAILURE,
} from '../actions/apiActions';

import type { TrackAction } from '../actions';
import type { Category } from './categories';
import type { Storyteller } from './storytellers';

export type AudioData = {
  duration: number;
  url: string;
};

export type Track = {
  id: string;
  title: string;
  storyteller: Storyteller;
  categories: Array<Category>;
  audio: AudioData;
  date: string;
};

export type TrackDictionary = {[id: string]: Track};

export default function tracks(
  state = TracksRecord(),
  action: TrackAction
) {
  switch (action.type) {
  case GET_TRACKS_REQUEST:
    return setLoading(state);
  case GET_TRACK_SUCCESS:
  case GET_TRACKS_SUCCESS:
    return mergeMeta(
      mergeEntities(
        clearMeta(state),
        action.entities.tracks
      ),
      action.meta
    );
  case GET_TRACKS_FAILURE:
    return setError(state, action.error, action.status);
  default:
    return state;
  }
}
