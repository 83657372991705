
import React, { Component } from 'react';
import { connect } from 'react-redux';
import classNames from 'classnames';
import styles from './Storyteller.module.scss';
import { storytellerSelector } from '../../selectors';

import { SlideInRight } from '../../containers/Animations';

// perfect scrollbar (to overwrite react-scrollbar)
import PerfectScrollbar from 'react-perfect-scrollbar';
import 'react-perfect-scrollbar/dist/css/styles.css';

import type { Storyteller } from '../../reducers/storytellers';

type P = {
  className?: string;
  storyteller: Storyteller;
  sponsors: any;
};

class StorytellerPanel extends Component<P, {}> {
  counter: number = 0;

  render() {
    const img = this.props.storyteller
        && this.props.storyteller.headshot
      ? this.props.storyteller.headshot?.url
      : null;
    const byline = (this.props.storyteller.title !== 'The Moth Radio Hour' && this.props.storyteller.title !== 'The Moth Podcast') ? 'by' : '';

    return (
      <div
        className={classNames([
          styles.container,
          this.props.className,
        ])}
      >
        {img
          && (
          <SlideInRight key={this.props.storyteller.headshot.url}>
            <div
              className={styles.headshot}
              style={{ backgroundImage: `url(${img})` }}
            />
          </SlideInRight>
        )}

        <div className={styles.copyContainer}>
          <h3 className={styles.author}>
            <small>{byline}</small>
            <SlideInRight key={this.props.storyteller.id}>
              <span className={styles.name}>
                {this.props.storyteller.title}
              </span>
            </SlideInRight>
          </h3>
          {!this.props.sponsors || !this.props.sponsors.sponsor
            ? null
            : <h4 className={styles.sponsor}>
                Presented by <a
                  href={this.props.sponsors.url || null}
                  target="_blank" rel="noreferrer"
                >{this.props.sponsors.sponsor}</a>
              </h4>}

          <PerfectScrollbar
            style={{ maxHeight: '100%' }}
            className={styles.scrollableArea}
            options={{
              wheelSpeed: 0.4
            }}
          >
            <div
              className={styles.biography}
              dangerouslySetInnerHTML={{ __html: this.props.storyteller.body ?? '' }}
            />
          </PerfectScrollbar>
        </div>
      </div>
    );
  }
}

export default connect(storytellerSelector)(StorytellerPanel);
