
import React from 'react';
import { useSpring, animated } from 'react-spring';

type Props = {
  children: any;
};

const SlideInRight: React.FC<Props> = ({ children }) => {
  const styles = useSpring({
    from: {
      x: -50,
      opacity: 0,
    },
    to: {
      x: 0,
      opacity: 1
    }
  })

  return <animated.div key={children.key} style={styles}>{children}</animated.div>;

};

export { SlideInRight };