
import { setCurrentTrackId } from './trackSelectionActions';
import { CALL_API, Schemas } from '../middleware/api';

import type { Track } from '../reducers/tracks';

export type ApiAction = {
  [api: symbol]: {
    types: Array<string>;
    endpoint: string;
    schema: any;
    params?: any;
  };
};

export type GetTracksRequestAction = {
  type: 'GET_TRACKS_REQUEST';
  result: Array<number>;
  entities: {[key: string]: Track};
};

export type GetTracksSuccessAction = {
  type: 'GET_TRACKS_SUCCESS' | 'GET_TRACK_SUCCESS',
  result: Array<number>;
  entities: {[key: string]: Track};
  schema: any;
  meta: { [key: string]: any };
};

export type GetTracksFailureAction = {
  type: 'GET_TRACKS_FAILURE',
  status: number;
  error: string;
};

type TrackRequestParams = {
  offset?: number;
  limit?: number;
};

export const GET_TRACKS_REQUEST = 'GET_TRACKS_REQUEST';
export const GET_TRACKS_SUCCESS = 'GET_TRACKS_SUCCESS';
export const GET_TRACKS_FAILURE = 'GET_TRACKS_FAILURE';

function requestTracks(params: TrackRequestParams): ApiAction {
  return {
    [CALL_API]: {
      types: [
        GET_TRACKS_REQUEST,
        GET_TRACKS_SUCCESS,
        GET_TRACKS_FAILURE,
      ],
      endpoint: 'tracks',
      schema: Schemas.TRACKS,
      params,
    },
  };
}

export type QueryParams = {
  offset?: number;
  limit?: number;
  term?: string;
  [key: string]: any;
};

export function getTracks(queryParams: QueryParams = {}): Function {
  return async (dispatch, getState) => {
    const { player, filters } = getState();
    const { currentTrackId, currentOffset, favorites, tracks } = player;
    const { term } = filters;
    const missing = favorites.subtract(tracks);

    const params = {
      offset: currentOffset,
      limit: 50,
      term: term || '',
      favorites: missing.toJS().join(','),
      ...queryParams,
    };

    await dispatch(requestTracks(params));

    if (!currentTrackId) {
      const state = getState();
      const trackId = state.player.get('sponsors').count()
        ? state.player.get('sponsors').first()
        : favorites.concat(state.player.get('tracks')).first();

      if (!state.player.currentTrackId) {
        dispatch(setCurrentTrackId(trackId));
      }
    }
  };
}

export const GET_SPONSOR_TRACKS_REQUEST = 'GET_SPONSOR_TRACKS_REQUEST';
export const GET_SPONSOR_TRACKS_SUCCESS = 'GET_SPONSOR_TRACKS_SUCCESS';
export const GET_SPONSOR_TRACKS_FAILURE = 'GET_SPONSOR_TRACKS_FAILURE';

export type GetSponsorTracksRequestAction = {
  type: 'GET_SPONSOR_TRACKS_REQUEST',
  result: Array<string>;
  entities: {[key: string]: Track};
};

export type GetSponsorTracksSuccessAction = {
  type: 'GET_SPONSOR_TRACKS_SUCCESS',
  result: Array<string>;
  entities: {[key: string]: Track};
  schema: any;
};

export type GetSponsorTracksFailureAction = {
  type: 'GET_SPONSOR_TRACKS_FAILURE',
  status: number;
  error: string;
};

export function getSponsorTracks(params: {}): ApiAction {
  return {
    // $FlowIssue: Flow hates computed symbol property names.
    [CALL_API]: {
      types: [
        GET_SPONSOR_TRACKS_REQUEST,
        GET_SPONSOR_TRACKS_SUCCESS,
        GET_SPONSOR_TRACKS_FAILURE,
      ],
      endpoint: 'tracks/sponsors',
      schema: Schemas.TRACKS,
      params,
    },
  };
}

export const GET_SPONSOR_REQUEST = 'GET_SPONSOR_REQUEST';
export const GET_SPONSOR_SUCCESS = 'GET_SPONSOR_SUCCESS';
export const GET_SPONSOR_FAILURE = 'GET_SPONSOR_FAILURE';

export function getSponsor(params: {}): ApiAction {
  return {
    [CALL_API]: {
      types: [
        GET_SPONSOR_REQUEST,
        GET_SPONSOR_SUCCESS,
        GET_SPONSOR_FAILURE,
      ],
      endpoint: 'sponsors',
      schema: Schemas.SPONSORS,
      params,
    },
  };
}

export type GetStorytellersRequestAction = {
  type: 'GET_STORYTELLERS_REQUEST',
  result: Array<string>;
  entities: {[key: string]: Track};
};

export type GetStorytellersSuccessAction = {
  type: 'GET_STORYTELLERS_SUCCESS',
  result: Array<string>;
  entities: {[key: string]: Track};
  schema: any;
};

export type GetStorytellersFailureAction = {
  type: 'GET_STORYTELLERS_FAILURE',
  status: number;
  error: string;
};

export const GET_STORYTELLERS_REQUEST = 'GET_STORYTELLERS_REQUEST';
export const GET_STORYTELLERS_SUCCESS = 'GET_STORYTELLERS_SUCCESS';
export const GET_STORYTELLERS_FAILURE = 'GET_STORYTELLERS_FAILURE';

export function getStorytellers(): ApiAction {
  return {
    [CALL_API]: {
      types: [
        GET_STORYTELLERS_REQUEST,
        GET_STORYTELLERS_SUCCESS,
        GET_STORYTELLERS_FAILURE,
      ],
      endpoint: 'storytellers',
      schema: Schemas.STORYTELLERS,
    },
  };
}

export const GET_LOCATIONS_REQUEST = 'GET_LOCATIONS_REQUEST';
export const GET_LOCATIONS_SUCCESS = 'GET_LOCATIONS_SUCCESS';
export const GET_LOCATIONS_FAILURE = 'GET_LOCATIONS_FAILURE';

export function getLocations(): ApiAction {
  return {
    [CALL_API]: {
      types: [
        GET_LOCATIONS_REQUEST,
        GET_LOCATIONS_SUCCESS,
        GET_LOCATIONS_FAILURE,
      ],
      endpoint: 'locations',
      schema: Schemas.LOCATIONS,
    },
  };
}
