
import { SponsorsRecord } from './lib/records';

import {
  setError,
} from './lib/core';

import {
  GET_SPONSOR_SUCCESS,
  GET_SPONSOR_FAILURE,
  GET_SPONSOR_TRACKS_SUCCESS,
} from '../actions/apiActions';

export default function sponsors(
  state = SponsorsRecord(),
  action: any
) {
  switch (action.type) {
  case GET_SPONSOR_SUCCESS:
    // @ts-ignore
    return state.update(obj => obj.merge(action.result));
  case GET_SPONSOR_FAILURE:
    return setError(state, action.error, action.status);
  case GET_SPONSOR_TRACKS_SUCCESS:
    let tracks;

    try {
      tracks = Object.keys(action.entities.tracks)
        .map(key => action.entities.tracks[key])
        .filter(obj => obj.audio && obj.audio.duration)
        .map(obj => Number(obj.audio.duration));
    } catch (e) {
      tracks = [];
    }

    // @ts-ignore
    return state.update(obj => obj.merge({ duration: tracks
      .reduce((a, b) => a + b, 0) }));
  default:
    return state;
  }
}
