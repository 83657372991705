import { createStore, applyMiddleware, compose, Store } from 'redux';
import { thunk } from 'redux-thunk';
import rootReducer from '../reducers';
import api from '../middleware/api';
import storage from './storage';
import hash from './hash';

// @todo: get root state
type RootState = {}

// Create the store with middleware
const finalCreateStore: any = compose(
  applyMiddleware(thunk, api),
  hash(),
  storage()
)(createStore);

// Define the Store type
type AppStore = Store<RootState>;

export function configureStore(initialState: any): AppStore {
  return finalCreateStore(rootReducer, initialState);
}
