
import React, { Component } from 'react';
import { connect } from 'react-redux';
import { createSelector } from 'reselect';
import classNames from 'classnames';
import styles from './Controls.module.scss';

import {
  nextTrack,
  previousTrack,
} from '../../actions/trackSelectionActions';

import {
  play,
  pause,
  stop,
} from '../../actions/playActions';

type P = {
  dispatch: Function;
  isPlaying: boolean;
  currentTime: number;
};

const getPressHandler = fn => {
  if ('ontouchstart' in window) {
    return {
      onTouchTap: (e) => fn(e),
    };
  }

  return {
    onClick: fn,
  };
};

class Controls extends Component<P, {}> {
  constructor(props) {
    super(props);

    this.handlePreviousClick = this.handlePreviousClick.bind(this);
    this.handlePlayClick = this.handlePlayClick.bind(this);
    this.handlePauseClick = this.handlePauseClick.bind(this);
    this.handleStopClick = this.handleStopClick.bind(this);
    this.handleNextClick = this.handleNextClick.bind(this);
  }

  handlePreviousClick(): void {
    this.props.dispatch(previousTrack());
  }

  handlePlayClick(): void {
    this.props.dispatch(play());
  }

  handlePauseClick(): void {
    this.props.dispatch(pause());
  }

  handleStopClick(): void {
    this.props.dispatch(stop());
  }

  handleNextClick(): void {
    this.props.dispatch(nextTrack());
  }

  renderPlayOrPause(): React.ReactNode {
    if (this.props.isPlaying) {
      return (
        <button
          {...getPressHandler(this.handlePauseClick)}
          className={classNames([
            styles.button,
            styles.active,
          ])}>
          <i className="fa fa-pause" />
        </button>
      );
    }

    return (
      <button
        {...getPressHandler(this.handlePlayClick)}
        className={classNames([styles.button])}>
        <i className="fa fa-play" />
      </button>
    );
  }

  render(): React.ReactNode {
    return (
      <div className={styles.container}>
        <button
          {...getPressHandler(this.handlePreviousClick)}
          className={styles.button}>
          <i className="fa fa-fast-backward" />
        </button>
        {this.renderPlayOrPause()}
        <button
          disabled={this.props.currentTime === 0}
            {...getPressHandler(this.handleStopClick)}
          className={styles.button}>
          <i className="fa fa-stop" />
        </button>
        <button
          {...getPressHandler(this.handleNextClick)}
          className={styles.button}>
          <i className="fa fa-fast-forward" />
        </button>
      </div>
    );
  }
}

const controlsSelector = createSelector(
  state => state.player.playing,
  state => state.player.currentTime,
  (isPlaying, currentTime) => {
    return {
      isPlaying,
      currentTime,
    };
  }
);

export default connect(controlsSelector)(Controls);
