
import React, { Component } from 'react';
import { connect } from 'react-redux';
import { createSelector } from 'reselect';
import classNames from 'classnames';
import moment from 'moment';
import styles from './Sponsor.module.scss';
import { SponsorsRecordProps } from '../../reducers/lib/records';

type P = {
  className?: string;
  duration: any;
  sponsor: string;
  slug: string;
  logo: string;
  logoBackgroundColor: string;
  url: string;
  summary: string;
  playlistContent: string;
  playlistName: string;
  tracks: any;
};

type S = {
  visorOpen: boolean;
};

class Sponsor extends Component <P, S> {
  constructor(props) {
    super(props);
    
    this.state = { visorOpen: false };
  }

  componentDidUpdate() {
    if (typeof(Event) === 'function') {
      // modern browsers
      window.dispatchEvent(new Event('resize'));
    } else {
      // for IE and other old browsers
      // causes deprecation warning on modern browsers
      const evt = window.document.createEvent('UIEvents');
      evt.initUIEvent('resize', true, false, window, 0);
      window.dispatchEvent(evt);
    }
  }

  getDuration(): string {
    return moment()
      .startOf('day')
      .seconds(this.props.duration)
      .format(`${this.props.duration >= 3600
        ? 'h[h] '
        : ''}mm[m] ss[s]`);
  }

  toggleVisor(): void {
    this.setState({
      visorOpen: !this.state.visorOpen,
    });
  }

  render() {
    return !this.props.sponsor
      ? null
      : (
        <div className={classNames([
          this.props.className,
          styles.container,
          this.state.visorOpen ? styles.visorIsOpen : null,
        ])}>
          <div
            className={styles.pane}
            style={!window.navigator.userAgent.match(/trident/ig) ? {} : { display: 'block' }}
          >
            {!this.props.logo
              ? null
              : <div
                    className={styles.logo}
                    style={{ backgroundColor: this.props.logoBackgroundColor
                      || 'transparent' }}
                  >
                    <img
                      src={this.props.logo}
                      alt={this.props.sponsor}
                    />
                </div>}
            <div className={styles.heading}>
              <h2>{this.props.playlistName} Playlist</h2>
              <h3>
                {'Presented by '}
                <a
                  href={this.props.url || ''}
                  target={'_blank'}
                  rel="noreferrer"
                >{this.props.sponsor}</a>
              </h3>
            </div>
            <div className={styles.tracks}>
              <p>{`${this.props.tracks.length} Stories`}{!this.props.duration
                ? null
                : ` • ${this.getDuration()}`}</p>
            </div>
            <div className={styles.summary}>
              <p>{this.props.summary}</p>
            </div>
            {!this.props.playlistContent
              ? null
              : <div className={styles.visorCta}>
                  <button
                    onClick={this.toggleVisor}
                  >About the Playlist</button>
                </div>}
          </div>
          {!this.props.playlistContent
            ? null
            : <div className={classNames([
              styles.visor,
              this.state.visorOpen ? styles.visorOpen : null,
            ])}>
                <div
                  className={styles.visorContent}
                  style={(!window.navigator.userAgent.match(/trident/ig)) ? {} : { display: 'block' }}
                >
                  <button
                    onClick={this.toggleVisor}
                    className={styles.visorClose}
                  ></button>
                  {!this.props.playlistContent
                    ? null
                    : <div>
                        <h3>About the Playlist</h3>
                        <div dangerouslySetInnerHTML={{
                          __html: this.props.playlistContent,
                        }}></div>
                    </div>}
                </div>
                <div
                  className={styles.visorOverlayBg}
                  onClick={this.toggleVisor}
                ></div>
              </div>}
        </div>
      );
  }
}

const mapStateToProps = createSelector(
  state => state.sponsors,
  state => state.player.get('sponsors'),
  (sponsors, sponsorsTracks) => {
    const sponsor: SponsorsRecordProps = sponsors.toJS();

    return {
      ...sponsor,
      tracks: sponsorsTracks.toJS(),
    }
  }
);

export default connect(mapStateToProps)(Sponsor);
