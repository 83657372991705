
import React, { Component } from 'react';
import { connect } from 'react-redux';
import { createSelector } from 'reselect';
import classNames from 'classnames';
import styles from './Discovery.module.scss';
import Select from '../Select';

import {
  storytellersSelector,
  getSearchTerm,
} from '../../selectors';

import {
  setQuery,
  setStoryteller,
  unsetStoryteller,
} from '../../actions/filterActions';

const headingSelector = createSelector(
  state => state.filters,
  state => state.storytellers.get('entities'),
  (
    filters,
    storytellers,
  ) => {
    return {
      term: filters.term,
      currentStoryteller: storytellers.get(filters.storytellerId),
    };
  },
);

const HeadingComponent: React.FC<{term: string; currentStoryteller: any}> = (props) => {
  const headingText = () => {
    if (!props.term && !props.currentStoryteller) {
      return 'Story Library';
    }

    const storytellerName = props.currentStoryteller
        && props.currentStoryteller.get('name')
      ? props.currentStoryteller.get('name')
      : null;

    if (storytellerName) {
      return `Results for stories by ${storytellerName}...`;
    }

    return props.term
      ? `Results for \`${props.term}\`...`
      : `Story Library`;
  };

  return (
    <div
      className={classNames([
        styles.heading,
      ])}
    >
      {headingText()}
    </div>
  );
}

const SearchComponent: React.FC<{ setQuery: Function; term: string; }> = (props) => (
  <div
    className={classNames([
      styles.filterGroup,
      styles.search,
    ])}
    style={!window.navigator.userAgent.match(/trident/i)
      ? {}
      : {minWidth: '17.5rem'}}
  >
    <div className={styles.prependedInput}>
      <i className={classNames(['fa', 'fa-search', styles.inputLabel])} />
      <input
        placeholder="Search by title..."
        type="search"
        value={props.term ?? ''}
        onChange={event => props.setQuery(event.currentTarget.value)}
      />
    </div>
  </div>
)

const StorytellersComponent: React.FC<{ setStoryteller: Function; unsetStoryteller: Function; selected: any; }> = (props) => (
  <div
      className={classNames([
        styles.filterGroup,
        styles.storytellers,
      ])}
    >
      <Select
        icon="fa fa-user"
        placeholder="Search by storyteller..."
        className={classNames([
          styles.input,
          styles.searchInput,
        ])}
        selected={props.selected}
        onChange={id => props.setStoryteller(id)}
        onClear={() => props.unsetStoryteller()}
        // @ts-ignore
        options={props.storytellers.map(person => ({
          value: person.id,
          label: person.title,
        })).toArray()}
      />
    </div>
)

const Heading = connect(headingSelector)(HeadingComponent);

const Search = connect(getSearchTerm, (dispatch) => ({
  // @ts-ignore
  setQuery: (value) => dispatch(setQuery(value))
}))(SearchComponent);

const Storytellers = connect(storytellersSelector, (dispatch) => ({
  // @ts-ignore
  setStoryteller: (id) => dispatch(setStoryteller(id)),
  // @ts-ignore
  unsetStoryteller: () => dispatch(unsetStoryteller()),
}))(StorytellersComponent);

type P = {
  className?: string;
  storytellers?: any;
  sponsors?: boolean;
};

class Discovery extends Component<P, {}> {
  render() {
    return (
      <div
        className={classNames([
          styles.container,
          this.props.className,
          this.props.sponsors
            ? styles.containerSponsor
            : null,
        ])}
      >
        <Heading />
        {this.props.sponsors
          ? null
          : <div
            className={classNames([
              styles.filterGroups,
            ])}
          >
            <Search />
            <Storytellers
              // @ts-ignore
              storytellers={this.props.storytellers}
            />
          </div>}
      </div>
    );
  }
}

export default Discovery;