
export const PLAY = 'PLAY';

export type PlayAction = {
  type: 'PLAY';
};

function _play(): PlayAction {
  return { type: PLAY };
}

export function play(): Function {
  return (dispatch, getState) => {
    const { player } = getState();
    const { context } = player;
    // @ts-ignore
    const { ga } = window;

    // @ts-ignore
    if (typeof ga !== 'undefined') {
      const { currentTrackId } = player;
      const track = getState().tracks.getIn([
        'entities',
        String(currentTrackId),
        'title',
      ]);

      // @ts-ignore
      ga('send', 'event', 'Story Engagement', 'Listen Now / Play', track);
    }


    if (context) {
      context.play();
    }

    dispatch(_play());
  };
}

export type PauseAction = {
  type: 'PAUSE';
};

export const PAUSE = 'PAUSE';

function _pause(): PauseAction {
  return { type: 'PAUSE' };
}

export function pause(): Function {
  return (dispatch, getState) => {
    const { context } = getState().player;

    if (context) {
      context.pause();
    }

    dispatch(_pause());
  };
}

export const STOP = 'STOP';

export type StopAction = {
  type: 'STOP';
};

function _stop(): StopAction {
  return { type: STOP };
}

export function stop(): Function {
  return (dispatch, getState) => {
    const { context } = getState().player;

    if (context) {
      context.stop();
    }

    dispatch(_stop());
  };
}

export const SET_TIME = 'SET_TIME';

export type SetTimeAction = {
  type: 'SET_TIME';
  time: number;
};

function _setTime(time: number): SetTimeAction {
  return {
    type: SET_TIME,
    time,
  };
}

export function setTime(time: number): Function {
  return (dispatch, getState) => {
    const { context } = getState().player;
    context.setTime(time);

    dispatch(_setTime(time));
  };
}

export const SET_PROGRESS_PREVIEW = 'SET_PROGRESS_PREVIEW';

export type SetProgressPreviewAction = {
  type: 'SET_PROGRESS_PREVIEW';
  time: number;
};

export function setProgressPreview(time: number): SetProgressPreviewAction {
  let progress: number;
  if (time > 100) {
    progress = 100;
  } else if (time < 0) {
    progress = 0;
  } else {
    progress = time;
  }
  return {
    type: SET_PROGRESS_PREVIEW,
    time: progress,
  };
}

export const UNSET_PROGRESS_PREVIEW = 'UNSET_PROGRESS_PREVIEW';

export type UnsetProgressPreviewAction = {
  type: 'UNSET_PROGRESS_PREVIEW';
};

export function unsetProgressPreview(): UnsetProgressPreviewAction {
  return {
    type: UNSET_PROGRESS_PREVIEW,
  };
}
