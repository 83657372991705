
import { PlayerRecord } from '../reducers/lib/records';
import { OrderedSet } from 'immutable';

type StoreFn = (next: Function) => (
  reducer: Function,
  initialState: { [key: string]: any }
) => {
  dispatch: Function;
  subscribe: Function;
  getState: Function;
};

const env = (process.env.NODE_ENV || 'development').trim();
export const KEY = `themoth:${env}`;

export default function storage(): StoreFn {
  return next => (reducer, initialState) => {
    let favorites;
    try {
      const json: string | null = localStorage.getItem(KEY);

      if (json) {
        const stored = JSON.parse(json);
        favorites = stored.favorites
          .filter(str => str);
      }
    } catch (exception) {
      favorites = [];
    }

    console.log('[initial storage]', favorites);

    const finalInitialState = {
      ...(initialState || {}),
      player: PlayerRecord({ favorites: OrderedSet(favorites) }),
    };

    const store = next(reducer, finalInitialState);

    store.subscribe(() => {
      const state = store.getState();
      const favorites = state.player.get('favorites').toArray();

      const serialized = JSON.stringify({
        favorites,
      });

      localStorage.setItem(KEY, serialized);
    });

    return store;
  };
}
