
import React, { Component } from 'react';
import classNames from 'classnames';
import styles from './Logo.module.scss';

type P = {
  className?: string;
};

export default class Logo extends Component<P, {}> {
  render() {
    return (
      <div className={classNames([
        styles.container,
        this.props.className,
      ])}>
        <div className="Logo"></div>
      </div>
    );
    /* eslint-enable */
  }
}
