
import axios from 'axios';
import { camelizeKeys } from 'humps';
import { normalize, schema } from 'normalizr';

import { Middleware } from 'redux';

const API_HOST = process.env.REACT_APP_API_HOST;
const API_ROOT = 'actions/_moth-admin-player/';

export const CALL_API = Symbol('call api');

class ApiError extends Error {
  status!: number;
}

const track = new schema.Entity('tracks');
const storyteller = new schema.Entity('storytellers');
const category = new schema.Entity('categories');
const location = new schema.Entity('locations');
const sponsors = new schema.Entity('sponsors');

track.define({
  categories: new schema.Array(category),
});

export const Schemas = {
  TRACK: track,
  TRACKS: new schema.Array(track),
  STORYTELLER: storyteller,
  STORYTELLERS: new schema.Array(storyteller),
  LOCATION: location,
  LOCATIONS: new schema.Array(location),
  SPONSORS: new schema.Array(sponsors),
};

export async function makeApiRequest(
  endpoint: string,
  schema: schema.Entity | {[key: string]: schema.Entity},
  params: {[key: string]: string} = {}
): Promise<any> {
  if (typeof schema === 'undefined') {
    throw new ApiError(
      'api.__makeApiRequest(...) expects param[1] to be ' +
      'a normalizr schema definition.'
    );
  }

  const BASE = `${API_HOST}/${API_ROOT}`;
  const query = Object.keys((params || {}))
    .reduce((acc, key) => {
      // @ts-ignore
      acc.push(`${key}=${encodeURIComponent(params[key])}`);

      return acc;
  }, []).join('&');

  const fullUrl = (endpoint.indexOf(BASE) === -1) ?
    BASE + endpoint :
    endpoint;

  const response = await axios.get(`${fullUrl}?${query}`);

  if (response.status < 200 || response.status >= 300) {
    const error = new ApiError();
    error.message = response.data;
    error.status = response.status;
    return Promise.reject(error);
  }

  let json;
  try {
    json = response.data;
  } catch (ex: any) {
    return Promise.reject(new ApiError(ex.message));
  }

  if (response.status !== 200) {
    return Promise.reject(new ApiError(json));
  }

  const camelizedJson = camelizeKeys(json);
  const metaJson = response.headers['x-meta'] || '{}';
  let meta: any;
  if (metaJson) {
    meta = JSON.parse(metaJson);
  }

  return {
    data: normalize(camelizedJson, schema),
    meta,
  };
}

// eslint-disable-next-line import/no-anonymous-default-export
const api: Middleware<{}, {}> = (store) => (next) => async (action: any) => {
      const request = action[CALL_API];

      if (typeof request === 'undefined') {
        return next(action);
      }

      let { endpoint } = request;
      const { schema, types, params } = request;

      if (typeof endpoint === 'function') {
        endpoint = endpoint(store.getState());
      }

      if (typeof endpoint !== 'string') {
        throw new Error('Expected endpoint to be a function or a string');
      }

      if (typeof schema === 'undefined') {
        throw new Error(
          'Expected schema to be an instance of normalizr schema'
        );
      }

      if (!(Array.isArray(request.types) && request.types.length === 3)) {
        throw new Error(
          `Expected types to be an array of [
            REQUEST,
            SUCCESS,
            FAILURE,
          ].`
        );
      }

      if (!types.every(type => typeof type === 'string')) {
        throw new Error(
          `Expected types to be an array of strings, received [
            ${typeof types[0]},
            ${typeof types[1]},
            ${typeof types[2]},
          ].`
        );
      }

      if (typeof params !== 'undefined' && typeof params !== 'object') {
        throw new Error(
          `Expected params to be an object, received ${typeof params}`
        );
      }

      function actionWith(data) {
        const finalAction = {
          ...action,
          ...data,
        };

        delete finalAction[CALL_API];
        return finalAction;
      }

      const [requestType, successType, failureType] = request.types;

      next(actionWith({
        type: requestType,
      }));

      try {
        const { data, meta } = await makeApiRequest(endpoint, schema, params);

        return next(actionWith({
          type: successType,
          meta,
          ...(Object.keys(data)
            .reduce((acc, key) => {
              acc[`${key}`] = data[key];
              return acc;
            }, {})),
        }));
      } catch (ex: any) {
        return next(actionWith({
          type: failureType,
          status: ex.status || 500,
          error: ex.message,
        }));
      }
    };

export default api;
