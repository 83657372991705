
import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Record } from 'immutable';

import classNames from 'classnames';
import Body from '../Body';
import CurrentTrack from '../CurrentTrack';
import Discovery from '../Discovery';
import Scrubber from '../Scrubber';
import Storyteller from '../Storyteller';
import Sponsor from '../Sponsor';
import TrackList from '../TrackList';

import styles from "./App.module.scss";

import {
  getTracks,
  getSponsor,
  getSponsorTracks,
  getStorytellers,
} from '../../actions/apiActions';
import { play } from '../../actions/playActions';
import { parseHash } from '../../store/hash';
import { setCurrentTrackId } from '../../actions/trackSelectionActions';
import { SponsorsRecordProps, TracksRecordProps } from '../../reducers/lib/records';

type S = {
  smallVerticalViewport: boolean;
  fetchedStory: number | null;
};

type P = {
  getStorytellers: Function;
  getSponsorTracks: Function;
  getTracks: Function;
  getSponsor: Function;
  setCurrentTrackId: Function;
  play: Function;
  tracks: Record<TracksRecordProps>;
  sponsors: SponsorsRecordProps;
}

class App extends Component <P, S> {
  constructor(props) {
    super(props);

    this.state = {
      smallVerticalViewport: false,
      fetchedStory: null,
    };

    this.onResize = this.onResize.bind(this);
  }

  componentDidMount(): void {
    const { sponsors, storyId } = parseHash();

    this.props.getStorytellers();

    if (sponsors) {
      this.props.getSponsorTracks({ sponsor: sponsors });
      this.props.getSponsor({ sponsor: sponsors })
    }

    this.props.getTracks({ sponsor: sponsors, storyId: storyId ?? '' });

    window.addEventListener('resize', this.onResize, false);
  }

  componentDidUpdate(): void {
    const { actionType, storyId, muted = '' } = parseHash();

    if (this.props.tracks.get('entities').count() > 0 && this.state.fetchedStory !== storyId) {
      // execute the action if there is one
      if (actionType === 'ADD_AND_PLAY') {
        this.props.setCurrentTrackId(storyId);

        if (muted !== '1') {
          this.props.play();
        }
      }

      this.setState({ fetchedStory: storyId });
      this.onResize();
    }
  }

  componentWillUnmount(): void {
    window.removeEventListener('resize', this.onResize);
  }

  onResize() {
    const height = ([
      styles.trackInfo,
      styles.scrubber,
      styles.discovery,
      window.innerWidth <= 768 && styles.sponsor
        ? styles.sponsor
        : null,
    ])
    .filter(str => str && document.querySelector(`.${str}`))
    .map(str => {
      if (document.querySelector(`.${str}`)) {
        return document.querySelector(`.${str}`)?.clientHeight;
      }

      return 0;
    })
    // @ts-ignore
    .reduce((a, b) => a + b, 0);

    this.setState({
      smallVerticalViewport: window.innerHeight - (height ?? 0) < 168,
    });
  }

  render() {
    return (
      <div id={'App'} className={classNames([
        styles.container,
        this.state.smallVerticalViewport
          ? styles.smallVerticalViewport
          : null,
      ])}>
        <Body className="body">
          <div className={styles.trackInfo}>
            <CurrentTrack className={styles.currentTrack} />
            <Storyteller className={styles.storyteller} />
          </div>

          <Scrubber />

          <div className={styles.lists}>
            <Sponsor className={styles.sponsor} />

            <div className={styles.listsWrapper}>
              <Discovery
                className={styles.discovery}
                sponsors={Boolean(this.props.sponsors.slug)}
              />

              <TrackList className={styles.trackList} />
            </div>
          </div>
        </Body>
      </div>
    );
  }
}

const mapStateToProps = state => {
  const sponsors: SponsorsRecordProps = state.sponsors.toJS();

  return {
    tracks: state.tracks,
    sponsors,
  }
}

const mapDispatchToProps = (dispatch) => {
  return {
    getSponsorTracks: (params) => dispatch(getSponsorTracks(params)),
    getStorytellers: () => dispatch(getStorytellers()),
    getTracks: (params) => dispatch(getTracks(params)),
    getSponsor: (params) => dispatch(getSponsor(params)),
    setCurrentTrackId: (id) => dispatch(setCurrentTrackId(id)),
    play: () => dispatch(play())
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(App);