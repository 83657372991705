
import { StorytellersRecord } from './lib/records';
import {
  mergeEntities,
  setLoading,
  setError,
  clearMeta,
} from './lib/core';

import {
  GET_STORYTELLERS_REQUEST,
  GET_STORYTELLERS_SUCCESS,
  GET_STORYTELLERS_FAILURE,
} from '../actions/apiActions';

export type Storyteller = {
  id: number;
  title: string;
  name: string;
  body?: string;
  headshot: { url: string };
};

export default function storytellers(
  prevState = StorytellersRecord(),
  action: any
) {
  let state;

  if (action.entities && action.entities.storytellers) {
    state = mergeEntities(prevState, action.entities.storytellers);
  } else {
    state = prevState;
  }

  switch (action.type) {
  case GET_STORYTELLERS_REQUEST:
    return setLoading(state);
  case GET_STORYTELLERS_SUCCESS:
    return clearMeta(state);
  case GET_STORYTELLERS_FAILURE:
    return setError(state, action.error, action.status);
  default:
    return state;
  }
}
