
import React, { Component } from 'react';
import { connect } from 'react-redux';
import { createSelector } from 'reselect';
import classNames from 'classnames';
import Loader from '../Loader';
import { getTracks } from '../../actions/apiActions';
import styles from './TrackList.module.scss';
import Track from './Track';

import {
  sponsorTracksSelector,
  favoriteTracksSelector,
  nonFavoriteTracksSelector,
  currentTrackSelector,
} from '../../selectors';

import type { List, OrderedSet } from 'immutable';
import { SponsorsRecordProps } from '../../reducers/lib/records';

const TrackListHeader = () => (
  <ul className={styles.trackListHeader}>
    <li className={styles.trackListHeaderTitle}>
      Title
    </li>
    <li className={styles.trackListHeaderStoryteller}>
      Storyteller
    </li>
    <li className={styles.trackListHeaderAirDate}>
      Date recorded
    </li>
    <li className={styles.trackListHeaderDuration}>
      Duration
    </li>
  </ul>
);

type P = {
  className?: string;
  dispatch: Function;
  isPlaying: boolean;
  isLoading: boolean;
  total: number;
  favorites: OrderedSet<number>;
  nonFavorites: OrderedSet<number>;
  currentTrackId: string;
  term?: string;
  isSearching: boolean;
  favoriteIds: List<number>;
  sponsorsIds: List<number>;
  sponsors: OrderedSet<SponsorsRecordProps>;
  sponsorsPlaylistName: string;
  currentStoryteller: any;
};

class TrackList extends Component<P, {}> {
  constructor(props) {
    super(props);

    this.handleScroll = this.handleScroll.bind(this);
    this.renderTrack = this.renderTrack.bind(this);
    this.renderFavoriteTrack = this.renderFavoriteTrack.bind(this);
    this.renderSponsorTrack = this.renderSponsorTrack.bind(this);
  }

  handleScroll(event: any): any {
    if (this.props.sponsors.count()) {
      return;
    }

    const total = this.props.favorites.count() +
      this.props.nonFavorites.count();
    const notDone = total < this.props.total;

    const {
      offsetHeight,
      scrollTop,
      scrollHeight,
    } = event.target;

    const diff = scrollHeight - (scrollTop + offsetHeight);

    if (diff < 250 && !this.props.isLoading && notDone) {
      this.props.dispatch(getTracks());
    }
  }

  renderTrack(track, key): React.ReactNode {
    if (!track) return null;
    const favorite = this.props.favoriteIds.indexOf(track.id) > -1;

    let leader: any;

    if (track.id === this.props.currentTrackId && this.props.isPlaying) {
      leader = (
        <i className={classNames([
          'fa',
          'fa-volume-up',
          styles.playingIcon,
        ])} />
      );
    } else {
      leader = (key + 1) + '. ';
    }

    return (
      <Track
        {...this.props}
        key={`track:${track.id}`}
        favorite={favorite}
        leader={leader}
        track={track}
        playing={this.props.isPlaying}
      />
    );
  }

  renderFavoriteTrack(track, key) {
    return this.renderTrack(track, key);
  }

  renderFavorites() {
    let header: Array<React.ReactNode>;

    if (this.props.favorites.count() > 0) {
      header = [
        <h3
          className={styles.subHeader}
          key="favorite-title"
        >
          Your Playlist
        </h3>,
        <TrackListHeader key="favorites-header" />,
      ];
    }

    const favoriteTracks = this.props.favorites
      .filter(obj => obj)
      .map(this.renderFavoriteTrack);

    return !favoriteTracks.count()
      ? null
      : (
        <div className={styles.listWrapper}>
          {/* @ts-ignore */}
          {header}
          <ul className={styles.list}>
            {favoriteTracks}
          </ul>
        </div>
      );
  }

  renderSponsorTrack(track, key): React.ReactNode {
    return this.renderTrack(track, key);
  }

  renderSponsor(): React.ReactNode {
    if (!this.props.sponsors.count()) {
      return null;
    }

    let header: Array<React.ReactNode>;

    header = [
      <h3
        className={styles.subHeader}
        key="sponsor-title"
      >
        {this.props.sponsorsPlaylistName || 'Sponsored'} Playlist
      </h3>,
      <TrackListHeader key="sponsor-header" />,
    ];

    const sponsorTracks = this.props.sponsors
      .filter(obj => obj)
      .map(this.renderSponsorTrack);

    return !sponsorTracks.count()
      ? null
      : (
        <div className={styles.listWrapper}>
          {this.props.currentStoryteller ? null : header}
          <ul className={styles.list}>
            {sponsorTracks}
          </ul>
        </div>
      );
  }

  renderTracks(): React.ReactNode {
    let header: Array<React.ReactNode> | null;

    if (this.props.currentStoryteller) {
      header = null;
    } else if (this.props.nonFavorites.count() > 0) {
      const title: string = this.props.term ?
        `Other Stories Matching '${this.props.term}'` :
        'Other Recent Stories';

      header = [
        <h3
          className={styles.subHeader}
          key="upcoming-title"
        >
          {title}
        </h3>,
        <TrackListHeader key="upcoming-header" />,
      ];
    }

    return (
      <div className={styles.listWrapper}>
        {/* @ts-ignore */}
        {header}
        <ul className={styles.list}>
          {this.props.nonFavorites.map(this.renderTrack)}
        </ul>
      </div>
    );
  }

  renderContent(): React.ReactNode {
    const { favorites, nonFavorites, isSearching, term } = this.props;
    const count = favorites.count() + nonFavorites.count();
    const hasContent = count > 0;
    const hasTerm = term && term.trim().length > 0;

    if (isSearching && hasTerm) {
      return (
        <div className={styles.message}>
          <span>Searching for `{term}`.</span>
        </div>
      );
    } else if (!hasContent && hasTerm) {
      return (
        <div className={styles.message}>
          <span>No results for `{term}`.</span>
        </div>
      );
    }

    return (
      <span>
        {this.renderSponsor()}
        {this.props.sponsors.count()
          ? null
          : this.renderFavorites()}
        {this.props.sponsors.count()
          ? null
          : this.renderTracks()}
      </span>
    );
  }

  render(): React.ReactNode {
    const { isLoading } = this.props;
    const content = this.renderContent();

    let loading: any;
    if (isLoading) {
      loading = (
        <Loader className={styles.loader} />
      );
    }

    return (
      <div className={classNames([
        styles.container,
        this.props.className,
      ])}>
        {loading}
        <div
          onScroll={this.handleScroll}
          className={styles.scrollableContainer}
        >
          {content}
        </div>
      </div>
    );
  }
}

const trackListSelector = createSelector(
  favoriteTracksSelector,
  sponsorTracksSelector,
  nonFavoriteTracksSelector,
  currentTrackSelector,
  state => state.player.playing,
  state => state.tracks.getIn(['meta', 'loading']),
  state => state.tracks.getIn(['meta', 'total']),
  state => state.filters,
  state => state.player.get('favorites'),
  state => state.player.get('sponsors'),
  state => state.sponsors.get('playlistName'),
  state => state.storytellers.get('entities'),
  (
    favorites,
    sponsors,
    nonFavorites,
    { track },
    isPlaying,
    isLoading,
    total,
    filters,
    favoriteIds,
    sponsorsIds,
    sponsorsPlaylistName,
    storytellers,
  ) => {
    return {
      favorites: favorites.tracks,
      sponsors: sponsors.tracks,
      nonFavorites: nonFavorites.tracks,
      currentTrackId: track.id,
      isPlaying,
      isLoading,
      total,
      term: filters.term,
      isSearching: filters.searching,
      favoriteIds: favoriteIds.toList(),
      sponsorsIds: sponsorsIds.toList(),
      sponsorsPlaylistName,
      currentStoryteller: storytellers.get(filters.storytellerId),
    };
  },
);

export default connect(trackListSelector)(TrackList);
