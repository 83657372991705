// @todo: verify production
import * as store from './configureStore.dev';

export type Store = {
  dispatch: Function;
  getState: Function;
};

// if (__PROD__) {
//   store = require('./configureStore.prod').configureStore;
// } else {
//   store = require('./configureStore.dev').configureStore;
// }

export const configureStore = store.configureStore;
