
import React, { Component, createRef, RefObject } from 'react';
import classNames from 'classnames';
import styles from './Select.module.scss';

type KeyEvent = {
  key: string;
  preventDefault: Function;
};

type Option = {
  label: string;
  value: number;
}

type P = {
  className?: string;
  placeholder?: string;
  options: Array<Option>;
  icon?: string;
  onChange: Function;
  onClear: Function;
  selected: number;
};

type S = {
  open: boolean;
  search? :string;
  value: number;
};

const sanitize = value => value.toLowerCase().replace(/[^A-Z\s]/gi, '');
const highlight = (label, search) => {
  if (!search) {
    return label;
  }
  const regexp = new RegExp(search.trim().split(' ').join('|'), 'gi');
  const segments = label.split(regexp);
  const matches = label.match(regexp);
  return segments.reduce((acc, seg, i) => {
    acc.push(
      <span key={`term:${i}-${seg}`}>{seg}</span>
    );

    if (segments.length === i + 1) {
      return acc;
    }

    if (matches && matches[i]) {
      acc.push(
        <span
          key={`highlight:${i}-${seg}`}
          className={styles.highlighted}
        >
          {matches[i]}
        </span>
      );
    }

    return acc;
  }, []);
};

export default class Select extends Component<P, S> {
  _input: RefObject<HTMLInputElement> = createRef();
  _list: RefObject<HTMLUListElement> = createRef();
  _container: RefObject<HTMLDivElement> = createRef();

  constructor(props) {
    super(props);

    this.state = {
      value: 0,
      open: false,
      search: undefined,
    };

    this.handleBlur = this.handleBlur.bind(this);
    this.handleShow = this.handleShow.bind(this);
    this.handleHide = this.handleHide.bind(this);
    this.handleSelect = this.handleSelect.bind(this);
    this.handleDeselect = this.handleDeselect.bind(this);
    this.handleKeyDown = this.handleKeyDown.bind(this);
    this.handleChange = this.handleChange.bind(this);
    this.handleSearch = this.handleSearch.bind(this);
    this.handlePropagation = this.handlePropagation.bind(this);
  }

  componentDidMount(): void {
    document.documentElement.addEventListener('click', this.handleBlur, false);
  }

  componentWillUnmount(): void {
    document.documentElement.removeEventListener('click', this.handleBlur);
  }

  setListFocus(): void {
    if (!this._list.current) {
      return;
    }

    const top = this._list.current.scrollTop;
    const bottom = top + this._list.current.offsetHeight;
    
    if (!this._list.current.children.length) {
      return;
    }

    const firstChild = this._list.current.children[0] as HTMLLIElement;
    const nodeHeight = firstChild.offsetHeight;
    const nodeTop = this.state.value * nodeHeight;
    const nodeBottom = nodeTop + nodeHeight;
    const inViewport = (nodeTop >= top) && (nodeBottom <= bottom);

    if (!inViewport) {
      this._list.current.scrollTop = nodeTop;
    }
  }

  getCurrentValue(): Option | null | undefined {
    if (!this.props.options) {
      return null;
    }
    const options = this.props.options.filter(this.handleSearch);
    return options[this.state.value];
  }

  getPreviousValue(): number {
    if (!this.props.options) {
      return this.state.value;
    }
    const size = this.props.options.filter(this.handleSearch).length;
    let nextIndex = this.state.value - 1;

    if (nextIndex < 0) {
      nextIndex = size - 1;
    }

    return nextIndex;
  }

  getNextValue(): number {
    if (!this.props.options) {
      return this.state.value;
    }
    const size = this.props.options.filter(this.handleSearch).length;
    let nextIndex = this.state.value + 1;

    if (nextIndex > size - 1) {
      nextIndex = 0;
    }

    return nextIndex;
  }

  handleBlur(event: Event): void {
    const node = this._container.current;
    const { target } = event;
    let inTree = false;
    let current = target;
    while (current) {
      if (current === node) {
        inTree = true;
        break;
      }

      // @ts-ignore
      current = current.offsetParent;
    }

    if (!inTree) {
      this.handleHide();
    }
  }

  handleShow(): void {
    this.setState({ open: true });
  }

  handleHide(): void {
    this.setState({ open: false });
  }

  handleSelect(): void {
    const current = this.getCurrentValue();
    if (!current) {
      return;
    }

    this.setState({
      search: undefined,
    }, () => {
      this.props.onChange(current.value);
      this.handleHide();
    });
  }

  handleDeselect(): void {
    this.props.onClear();
  }

  handleKeyDown(event: KeyEvent): void {
    switch (event.key) {
    case 'Escape':
      this.handleHide();
      event.preventDefault();
      break;
    case 'Enter':
      this.handleSelect();
      event.preventDefault();
      break;
    case 'ArrowUp':
      this.setState({
        value: this.getPreviousValue(),
      }, () => this.setListFocus());
      event.preventDefault();
      break;
    case 'ArrowDown':
      this.setState({
        value: this.getNextValue(),
      }, () => this.setListFocus());
      event.preventDefault();
      break;
    default:
      this.setState({ value: 0 });
      this.handleShow();
      break;
    }
  }

  handleChange(): void {
    this.setState({
      search: sanitize(this._input.current?.value),
    });
  }

  handleSearch(option: Option): boolean {
    if (!this.state.search) {
      return true;
    }

    return !!~sanitize(option.label).indexOf(this.state.search);
  }

  handlePropagation(event: React.MouseEvent<HTMLDivElement>): void {
    event.stopPropagation();
  }

  renderIcon() {
    if (this.props.icon) {
      return (
        <i
          className={classNames([
            styles.icon,
            this.props.icon,
          ])}
        />
      );
    }
  }

  renderOptions() {
    if (!this.props.options) {
      return null;
    }

    return this.props.options.filter(this.handleSearch).map((option, i) => {
      const isActive = i === this.state.value;
      const classList = classNames([
        styles.listItem,
        { [styles.active]: isActive },
      ]);

      const select = () => {
        this.setState({ value: i }, this.handleSelect);
      };

      return (
        <li
          key={option.value}
          className={classList}
          onClick={select}
        >
          {highlight(option.label, this.state.search)}
        </li>
      );
    });
  }

  renderList() {
    if (this.state.open) {
      const options = this.renderOptions();
      return (
        <ul
          className={styles.list}
          ref={this._list}
        >
          {options}
        </ul>
      );
    }
  }

  renderLabel() {
    if (this.props.selected) {
      const option = this.props.options.find(
        opt => opt.value === this.props.selected
      );

      const label = option ? option.label : '';

      const closeClassList = classNames([
        'fa',
        'fa-close',
        styles.labelClose,
      ]);

      return (
        <span className={styles.label}>
          <span className={styles.labelText}>
            {label}
          </span>
          <i
            className={closeClassList}
            onClick={this.handleDeselect}
          />
        </span>
      );
    }
  }

  renderInput() {
    if (!this.props.selected) {
      return (
        <input
          ref={this._input}
          type="text"
          className={styles.input}
          placeholder={this.props.placeholder}
          onClick={this.handleShow}
          onKeyDown={this.handleKeyDown}
          onChange={this.handleChange}
        />
      );
    }
  }

  render() {
    const icon = this.renderIcon();
    const list = this.renderList();
    const label = this.renderLabel();
    const input = this.renderInput();

    return (
      <div
        ref={this._container}
        className={styles.container}
        onClick={this.handlePropagation}
      >
        {icon}
        {label}
        {input}
        {list}
      </div>
    );
  }
}
