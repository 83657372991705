
import { PlayerRecord } from './lib/records';

import {
  setCurrentTrackId,
  startPlaying,
  pausePlaying,
  setTime,
  setProgressPreview,
  unsetProgressPreview,
  updateOffset,
  mergeTrackList,
  clearTrackList,
  setFavorite,
  unsetFavorite,
  unsetQuery,
} from './lib/core';

import {
  SET_CURRENT_TRACK_ID,
  TIMEUPDATE,
  SET_FAVORITE,
  UNSET_FAVORITE,
} from '../actions/trackSelectionActions';

import {
  GET_TRACKS_SUCCESS,
  GET_SPONSOR_TRACKS_SUCCESS,
} from '../actions/apiActions';

import { GET_TRACK_SUCCESS } from '../store/hash';
import { CLEAR_TRACK_CACHE, UNSET_QUERY } from '../actions/filterActions';

import {
  PLAY,
  PAUSE,
  STOP,
  SET_PROGRESS_PREVIEW,
  UNSET_PROGRESS_PREVIEW,
} from '../actions/playActions';

import type { TrackAction } from '../actions';

export type NetworkState = {
  status?: number;
  error?: string;
  loading: boolean;
};

export default function player(
  state = PlayerRecord(),
  action: TrackAction
) {
  switch (action.type) {
  case UNSET_QUERY:
    return unsetQuery(state, action.ids);
  case GET_TRACK_SUCCESS:
  case GET_TRACKS_SUCCESS:
    return mergeTrackList(
      updateOffset(
        state,
        action.result.length
      ),
      action.entities.tracks,
    );
  case GET_SPONSOR_TRACKS_SUCCESS:
    // @ts-ignore
    return state.update('sponsors', sponsors => sponsors.concat(action.result));
  case SET_CURRENT_TRACK_ID:
    return setCurrentTrackId(state, action.id, action.context);
  case PLAY:
    return startPlaying(state);
  case STOP:
    return pausePlaying(state);
  case PAUSE:
    return setTime(pausePlaying(state), 0);
  case TIMEUPDATE:
    return setTime(state, action.time);
  case SET_PROGRESS_PREVIEW:
    return setProgressPreview(state, action.time);
  case UNSET_PROGRESS_PREVIEW:
    return unsetProgressPreview(state);
  case CLEAR_TRACK_CACHE:
    return clearTrackList(state);
  case SET_FAVORITE:
    return setFavorite(state, action.trackId);
  case UNSET_FAVORITE:
    return unsetFavorite(state, action.trackId);
  default:
    return state;
  }
}
