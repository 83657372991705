import { CategoriesRecord } from './lib/records';
import { mergeEntities } from './lib/core';

export type Category = {
  id: string;
  title: string;
}

export default function categories(
  state = CategoriesRecord(),
  action: any
) {
  if (action.entities && action.entities.categories) {
    return mergeEntities(state, action.entities.categories);
  }

  return state;
}
