
import React, { Component, createRef, RefObject } from 'react';
import { connect } from 'react-redux';
import { createSelector } from 'reselect';
import moment from 'moment';
import classNames from 'classnames';
import styles from './CurrentTrack.module.scss';
import Logo from '../Logo';

import {
  setTime,
  setProgressPreview,
  unsetProgressPreview,
} from '../../actions/playActions';

import {
  currentTrackSelector,
} from '../../selectors';
import Controls from '../Controls';

import { SlideInRight } from '../../containers/Animations';
import type { Track } from '../../reducers/tracks';

type P = {
  className?: string;
  dispatch: Function;
  currentTime: number;
  // duration: number;
  length: string;
  progressPreview?: number;
  track: Track;
  elapsed: string;
};

type S = {
  initialDragX?: number;
};

class CurrentTrack extends Component<P, S> {
  containerRef: RefObject<HTMLDivElement> = createRef();

  constructor(props) {
    super(props);

    this.state = { initialDragX: undefined };

    this.getProgressPreview = this.getProgressPreview.bind(this);
    this.handleDragInit = this.handleDragInit.bind(this);
    this.handleDragEnd = this.handleDragEnd.bind(this);
  }

  getProgressPreview(clientX: number): number {
    const { currentTime, track } = this.props;
    const { initialDragX } = this.state;
    
    if (!this.containerRef.current) {
      return 0;
    }

    const { width } = this.containerRef.current.getBoundingClientRect();

    const currentProgress = currentTime / track.audio.duration;
    return currentProgress + (clientX - (initialDragX || 0)) / width;
  }

  handleDragInit(event: React.MouseEvent<HTMLDivElement>): void {
    const { clientX } = event;

    document.addEventListener('mousemove', this.handleDragMove, false);
    document.addEventListener('mouseup', this.handleDragEnd, false);

    this.setState({ initialDragX: clientX }, () => {
      const nextProgress = this.getProgressPreview(clientX);

      this.props.dispatch(setProgressPreview(100 * nextProgress));
    });
  }

  // handleDragMove: EventHandler;
  handleDragMove(event: MouseEvent): void {
    const nextProgress = this.getProgressPreview(event.clientX);
    this.props.dispatch(setProgressPreview(100 * nextProgress));
  }

  // handleDragEnd: EventHandler;
  handleDragEnd(event: MouseEvent): void {
    const nextProgress = this.getProgressPreview(event.clientX);
    const nextTime = nextProgress * (this.props.track.audio.duration || 0);

    this.props.dispatch(setTime(nextTime));
    this.props.dispatch(unsetProgressPreview());

    this.setState({ initialDragX: undefined });
    document.removeEventListener('mousemove', this.handleDragMove);
    document.removeEventListener('mouseup', this.handleDragEnd);
  }

  render() {
    return (
      <div className={classNames([
        styles.container,
        this.props.className,
      ])}
      ref={this.containerRef}>
        <Logo className={styles.logo} />
        <div className={styles.titleBlock}>
          <span className={styles.now}>Now listening to</span>
          <SlideInRight key={this.props.track.id}>
            <h2 className={styles.title}>
              {this.props.track.title}
            </h2>
          </SlideInRight>
          <div className={styles.time}>
            <div
              onMouseDown={this.handleDragInit}
              className={styles.elapsed}>
              {this.props.elapsed}
            </div>
            <svg
              viewBox="0 0 14 20"
              className={styles.accent}>
              <path d="m0,20 L14,0" />
            </svg>
            <span className={styles.duration}>
              {this.props.length}
            </span>
          </div>
        </div>
        <Controls />
      </div>
    );
  }
}

const trackInfoSelector = createSelector(
  currentTrackSelector,
  state => state.player.currentTime,
  state => state.player.progressPreview,
  ({ track }, currentTime, progressPreview) => {
    const length = moment()
      .startOf('day')
      .seconds(track.audio.duration)
      .format('mm:ss');


    let time: number;
    if (progressPreview) {
      time = track.audio.duration * (progressPreview / 100);
    } else {
      time = currentTime;
    }

    const elapsed = moment()
      .startOf('day')
      .seconds(time)
      .format('mm:ss');

    return {
      length,
      track,
      elapsed,
      currentTime,
      progressPreview,
    };
  },
);

export default connect(trackInfoSelector)(CurrentTrack);
