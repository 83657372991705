
import {
  SET_QUERY,
  CLEAR_ALL_FILTERS,
  SET_STORYTELLER_ID,
  SET_LOCATION_ID,
} from '../actions/filterActions';

import { FilterState } from './lib/records';

import {
  clearAllFilters,
  setQuery,
  setStorytellerId,
  setLocationId,
} from './lib/core';

import type { FilterAction } from '../actions/filterActions';

export default function filters(
  state = FilterState(),
  action: FilterAction
) {
  switch (action.type) {
  case SET_QUERY:
    return setQuery(state, action.term);
  case SET_STORYTELLER_ID:
    return setStorytellerId(state, action.id);
  case SET_LOCATION_ID:
    return setLocationId(state, action.id);
  case CLEAR_ALL_FILTERS:
    return clearAllFilters(state);
  default:
    return state;
  }
}
