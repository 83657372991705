import { OrderedSet } from "immutable";

export function setCurrentTrackId(
  state,
  currentTrackId: number,
  context: any
) {
  return state.merge({
    currentTrackId,
    context,
  });
}

export function startPlaying(state) {
  return state.set('playing', true);
}

export function pausePlaying(state) {
  return state.set('playing', false);
}

export function setTime(
  state,
  time: number
) {
  return state.set('currentTime', time);
}

export function setProgressPreview(
  state,
  time: number
) {
  return state.set('progressPreview', time);
}

export function unsetProgressPreview(state) {
  return state.set('progressPreview', null);
}

export function mergeEntities(
  state,
  entities: {[key: string]: any}
) {
  return state.update('entities', prev => prev.mergeDeep(entities));
}

export function mergeMeta(
  state,
  meta: {[key: string]: any}
) {
  return state.update('meta', m => m.merge({
    ...meta,
  }));
}

export function updateOffset(
  state,
  offset: number
) {
  return state.update(
    'currentOffset',
    0,
    currentOffset => currentOffset + offset
  );
}

export function sortByDate(
  state
) {
  return state.update('entities', entities => entities.sortBy(
    entity => entity.date,
    (a, b) => a < b ? -1 : 1,
  ));
}

export function setLoading(state) {
  return state.setIn(['meta', 'loading'], true);
}

export function clearMeta(state) {
  return state.mergeDeep({
    meta: {
      error: null,
      status: null,
      loading: false,
    },
  });
}

export function setError(
  state,
  error: Error | string,
  status: number
) {
  return state.mergeDeep({
    meta: {
      error,
      status,
      loading: false,
    },
  });
}

export function setFavorite(
  state,
  trackId: number
) {
  console.log('[set]', trackId);
  return state.update('favorites', favorites => favorites.add(trackId));
}

export function unsetFavorite(
  state,
  trackId: number
) {
  console.log('[unset]', trackId);
  return state.update('favorites', favorites => favorites.delete(trackId));
}

export function setQuery(
  state,
  term: string
) {
  return state.set('term', term);
}

export function clearTrackList(state) {
  return state.update('tracks', tracks => tracks.clear())
    .set('currentOffset', 0);
}

export function mergeTrackList(
  state,
  trackEntities: { [key: string]: any }
) {
  const sorted = Object.keys(trackEntities)
    .map(key => trackEntities[key])
    .sort((a, b) => {
      if (a.date < b.date) {
        return 1;
      } else if (a.date > b.date) {
        return -1;
      } else if (a.id > b.id) {
        return 1;
      }

      return -1;
    })
    .map(track => track.id);

  return state.update('tracks', tracks => tracks.concat(sorted));
}

export function startSearch(state) {
  return state.set('searching', true);
}

export function stopSearch(state) {
  return state.set('searching', false);
}

export function setStorytellerId(
  state,
  id: number | null
) {
  return state.set('storytellerId', id);
}

export function setLocationId(
  state,
  id: number | null
) {
  return state.set('locationId', id);
}

export function unsetQuery(
  state,
  ids: Array<number>
) {
  return state.set('tracks', OrderedSet(ids));
}

export function clearAllFilters(state) {
  return state
    .set('storytellerId', null)
    .set('locationId', null);
}
