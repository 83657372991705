
import { combineReducers } from 'redux';
import player from './player';
import tracks from './tracks';
import storytellers from './storytellers';
import categories from './categories';
import filters from './filters';
import sponsors from './sponsors';

export default combineReducers({
  sponsors,
  player,
  filters,
  storytellers,
  tracks,
  categories,
});
